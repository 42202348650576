import { createSlice } from '@reduxjs/toolkit'

const emailSlice = createSlice({
  name: 'emailTemplate',
  initialState: {},
  reducers: {
    emailData: (state, action) => {
      state.email_data = (action.payload);
    },
  }
})

export const { emailData } = emailSlice.actions;
export default emailSlice.reducer;