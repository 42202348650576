import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { Grid, Select, LegacyCard, Page, FormLayout, Toast, TextField, Button, Divider, Link } from '@shopify/polaris';
import { UploadIcon } from '../components/commonSvg';
// import { ApiCall } from '../helper/axios';
import { useSelector, useDispatch } from 'react-redux';
// import { storeData } from '../store/storeSlice';
import { NotificationFilledMajor } from '@shopify/polaris-icons';

const FeatureReq = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const clientStoreData = useSelector(state => state.clientStoreData);
    const storeInfo = clientStoreData.clientInfo?.storeInfo;
    const header = { authentication: storeInfo?.token };
    const shop = storeInfo?.shop_data?.store_name
    const app_status = storeInfo?.shop_data?.app_status;
    const [appStatus, setAppStatus] = useState(app_status);
    const [activeEnable, setActiveEnable] = useState(false);
    const [loading, setLoading] = useState(false);

    const toggleActive = useCallback(
        () => setActiveEnable((activeEnable) => !activeEnable),
        []
    );

    const toastMarkup = activeEnable ? (
        <Toast
            duration={5000}
            content={appStatus ? "Disable Successfully" : "Enable Successfully"}
            onDismiss={toggleActive}
        />
    ) : null;

    const options = [
        {
            label: 'Trending',
            value: 'trending',
            prefix: 'Order:',
        }
    ];

    return (
        <>
            <Page fullWidth>
                <Grid>
                    <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 3, xl: 3 }}>
                        <div className='feature_div'>
                            <LegacyCard title="Submit a Feature Request" sectioned>
                                <span>Use the form to submit a feature request to our team.</span>
                                <FormLayout>
                                    <TextField label="Store name" onChange={() => { }} autoComplete="off" placeholder='Short, descriptive title' />
                                    <TextField
                                        label="Feature details"
                                        // value={value}
                                        // onChange={handleChange}
                                        multiline={4}
                                        autoComplete="off"
                                        placeholder='Any additional details...'
                                    />
                                    <div className='feature_btnGrp display-flex'>
                                        <div className='upload_icon_div'>
                                            <Button icon={UploadIcon}>Upload file</Button>
                                        </div>
                                        <div>
                                            <Button primary>Subimt</Button>
                                        </div>
                                    </div>
                                </FormLayout>
                            </LegacyCard>
                        </div>
                    </Grid.Cell>
                    <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 9, xl: 9 }}>
                        <LegacyCard sectioned>
                            <div>
                                <div className='select-div'>
                                    <Select
                                        options={options}
                                    />
                                </div>
                                <div className='search-div display-flex'>
                                    <TextField
                                        placeholder='Search'
                                        prefix={'$'}
                                    />
                                    <div className='noti_div'>
                                        <Button icon={NotificationFilledMajor}></Button>
                                    </div>
                                </div>
                                <div className='clear'></div>
                            </div>
                            <div className='pt-15'>
                                <Divider />
                            </div>
                            <div>
                                <ul className="Polaris-ResourceList">
                                    <li className="list-li">
                                        <div>
                                            <div className="Polaris-LegacyStack--noWrap Polaris-LegacyStack">
                                                <div className="Polaris-LegacyStack__Item">
                                                    <div id="upvoteChange" className="vote-btn cursor-pointer">
                                                        <svg width="13" height="8" viewBox="0 0 13 8" fill="none">
                                                            <path d="M1.44332 8.00076H11.3584C12.5602 8.00076 13.2339 6.79418 12.4914 5.97166L7.53383 0.479635C6.95774 -0.158861 5.84558 -0.158861 5.26789 0.479635L0.310347 5.97326C-0.432165 6.79418 0.241537 8.00076 1.44332 8.00076Z" fill="#5C5F62" />
                                                        </svg>
                                                        <div className="vote-num">
                                                            <span data-upvote="20">9</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="Polaris-LegacyStack__Item Polaris-LegacyStack__Item--fill w-100">
                                                    <div className='mb-2'>
                                                        <div onClick={() => navigate('/feature_req_comment')}>
                                                            <h3 className="Polaris-Text--root cursor-pointer Polaris-Text--headingMd">Multi language feature</h3>
                                                        </div>
                                                        <div className='comment-div'>
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11 8C11 8.26522 11.1054 8.51957 11.2929 8.70711C11.4805 8.89464 11.7348 9 12 9C12.2652 9 12.5196 8.89464 12.7071 8.70711C12.8947 8.51957 13 8.26522 13 8C13 7.73478 12.8947 7.48043 12.7071 7.29289C12.5196 7.10536 12.2652 7 12 7C11.7348 7 11.4805 7.10536 11.2929 7.29289C11.1054 7.48043 11 7.73478 11 8ZM7.00003 8C7.00003 8.26522 7.10539 8.51957 7.29293 8.70711C7.48046 8.89464 7.73482 9 8.00003 9C8.26525 9 8.5196 8.89464 8.70714 8.70711C8.89468 8.51957 9.00003 8.26522 9.00003 8C9.00003 7.73478 8.89468 7.48043 8.70714 7.29289C8.5196 7.10536 8.26525 7 8.00003 7C7.73482 7 7.48046 7.10536 7.29293 7.29289C7.10539 7.48043 7.00003 7.73478 7.00003 8ZM3.00003 8C3.00003 8.26522 3.10539 8.51957 3.29293 8.70711C3.48046 8.89464 3.73482 9 4.00003 9C4.26525 9 4.5196 8.89464 4.70714 8.70711C4.89468 8.51957 5.00003 8.26522 5.00003 8C5.00003 7.73478 4.89468 7.48043 4.70714 7.29289C4.5196 7.10536 4.26525 7 4.00003 7C3.73482 7 3.48046 7.10536 3.29293 7.29289C3.10539 7.48043 3.00003 7.73478 3.00003 8ZM8.00003 0C3.58903 0 3.27184e-05 3.589 3.27184e-05 8C3.27184e-05 9.504 0.425033 10.908 1.15003 12.111L0.0810328 14.606C0.00216619 14.7898 -0.0197771 14.993 0.0180376 15.1894C0.0558523 15.3858 0.151688 15.5663 0.293163 15.7077C0.434638 15.8491 0.615253 15.9448 0.811675 15.9824C1.0081 16.0201 1.2113 15.998 1.39503 15.919L3.88903 14.85C5.12867 15.6015 6.55041 15.9992 8.00003 16C12.411 16 16 12.411 16 8C16 3.589 12.411 0 8.00003 0Z" fill="#5C5F62" />
                                                            </svg>
                                                            <span className='comment-num'>0</span>
                                                        </div>
                                                    </div>
                                                    <p className="Polaris-Text--root Polaris-Text--subdued">We need to send notifications based on the language the customers use to navigate the page.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </LegacyCard>
                    </Grid.Cell>
                </Grid>
                {toastMarkup}
            </Page >
        </>
    );
}

export default FeatureReq;