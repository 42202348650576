import { BrowserRouter as AppRouter, Route, Routes } from "react-router-dom";
import Dashboard from '../Pages/Dashboard';
import ProductFilter from "../Pages/ProductFilter";
import Customer from "../Pages/Customer";
import Setting from "../Pages/Setting";
import SettingTheme from "../Pages/SettingTheme";
import SettingEmail from "../Pages/SettingEmail";
import SettingProduct from "../Pages/SettingProduct";
import SettingProductAvailable from "../Pages/SettingProductAvailable";
import SettingCustomCSS from "../Pages/SettingCustomCSS";
import EditSettingEmail from "../Pages/EditSettingEmail";
import EditEmail from "../Pages/EditEmail";
import PricingPlan from "../Pages/PricingPlan";
import FeatureReq from "../Pages/FeatureReq";
import FeatureReqComment from '../Pages/FeatureReqComment';

const Router = () => {
    return (
        <AppRouter>
            <Routes>
                <Route
                    path={"/"}
                    exact={true}
                    element={<Dashboard />}
                />
                <Route
                    path={"/product"}
                    exact={true}
                    element={<ProductFilter />}
                />
                <Route
                    path={"/customer"}
                    exact={true}
                    element={<Customer />}
                />
                <Route
                    path={"/setting-theme"}
                    exact={true}
                    element={<SettingTheme />}
                />
                <Route
                    path={"/setting-email"}
                    exact={true}
                    element={<SettingEmail />}
                />
                <Route
                    path={"/setting-product"}
                    exact={true}
                    element={<SettingProduct />}
                />
                <Route
                    path={"/setting-product-available"}
                    exact={true}
                    element={<SettingProductAvailable />}
                />
                <Route
                    path={"/setting-customcss"}
                    exact={true}
                    element={<SettingCustomCSS />}
                />
                <Route
                    path={"/create-setting-email"}
                    exact={true}
                    element={<EditSettingEmail />}
                />
                <Route
                    path={"/edit-setting-email/:id"}
                    exact={true}
                    element={<EditSettingEmail />}
                />
                <Route
                    path={"/edit-setting-email"}
                    exact={true}
                    element={<EditSettingEmail />}
                />
                <Route
                    path={"/setting"}
                    exact={true}
                    element={<Setting />}
                />
                <Route
                    path={"/edit_email"}
                    exact={true}
                    element={<EditEmail />}
                />
                <Route
                    path={"/edit_email/:id"}
                    exact={true}
                    element={<EditEmail />}
                />
                <Route
                    path={"/pricing_plan"}
                    exact={true}
                    element={<PricingPlan />}
                />
                <Route
                    path={"/feature_req"}
                    exact={true}
                    element={<FeatureReq />}
                />
                <Route
                    path={"/feature_req_comment"}
                    exact={true}
                    element={<FeatureReqComment />}
                />
            </Routes>
        </AppRouter>
    );
};


export default Router;
