import React, { useRef, useState, useCallback } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import EmailEditor from 'react-email-editor';
import { Button, FormLayout, LegacyCard, Page, LegacyStack, Grid, TextField, Toast } from '@shopify/polaris';
import { ApiCall, GetApiCall } from '../helper/axios';
import { useSelector, useDispatch } from 'react-redux';
import { emailData } from '../store/emailSlice';
import { useFormik } from 'formik';
import * as Yup from "yup";

const Edit_email = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const emailEditorRef = useRef(null);
    const [preview, setPreview] = useState(false);
    const clientStoreData = useSelector(state => state.clientStoreData);
    const storeInfo = clientStoreData.clientInfo?.storeInfo;
    const [activeEnable, setActiveEnable] = useState(false);
    const [loading, setLoading] = useState(false);
    const header = { authentication: storeInfo?.token };
    const [toastMsg, setToastMsg] = useState('');
    const [sub, setSub] = useState('');
    const { id } = useParams();
    const emailData = useSelector(state => state.emailData);

    const toggleActive = useCallback(
        () => setActiveEnable((activeEnable) => !activeEnable),
        []
    );

    const toastMarkup = activeEnable ? (
        <Toast
            duration={5000}
            content={toastMsg}
            onDismiss={toggleActive}
        />
    ) : null;

    const exportHtml = (type) => {
        const unlayer = emailEditorRef.current?.editor;
        unlayer?.exportHtml((data) => {
            const { design, html } = data;
            if (type === "save") {
                saveEmail(design, html);
            }else if(type === "testEmail"){
                sentTestEmail(design, html);
            }
        });
    };

    const togglePreview = () => {
        const unlayer = emailEditorRef.current?.editor;
        if (preview) {
            unlayer?.hidePreview();
            setPreview(false);
        } else {
            unlayer?.showPreview('desktop');
            setPreview(true);
        }
    };

    const saveEmail = async (design, html) => {
        setLoading(true);
        const data = {
            store_client_id: storeInfo?.shop_data?.store_client_id,
            email_sub: emailData?.email_data?.email_sub,
            from_email_address: emailData?.email_data?.from_email_address,
            email_design: btoa(JSON.stringify(design)),
            email_html: btoa(JSON.stringify(html))
        };
        let res = '';
        if (id) {
            res = await ApiCall('PUT', `/update_email_temp?id=${id}`, data, header);
        } else {
            res = await ApiCall('POST', '/save_email_temp', data, header);
        }
        if (res?.data?.statusCode === 200) {
            setToastMsg(res.data.message);
            toggleActive();
            setLoading(false);
            // navigate('/setting');
        }
    }

    const onLoad = async () => {
        if (id) {
            let response = await GetApiCall("GET", `/get-emailtemplate?id=${id}`, header);
            if (response?.data?.statusCode === 200) {
                const template = JSON.parse(atob(response.data?.data?.email_design));
                emailEditorRef.current.editor.loadDesign(template);
                setSub(response.data?.data?.email_sub);
            }
        }
    }

    const editTemplate = () => {
        if (id) {
            navigate(`/edit-setting-email/${id}`);
        } else {
            navigate(`/edit-setting-email`);
        }
    }

    let [initialState, setInitialState] = useState({
        test_email : ''
    });

    let formSchema = Yup.object().shape({
        test_email: Yup.string().email('Please enter a valid email address').required('Send test email field is required'),
    });

    const formik = useFormik({
        initialValues: initialState,
        validationSchema: formSchema,
        enableReinitialize: true,
        onSubmit: () => {
            exportHtml('testEmail');
        }
    });

    const sentTestEmail = async (design, html) => {
        let data = {
            store_client_id: storeInfo?.shop_data?.store_client_id,
            html: html,
            emailTo: formik.values.test_email,
            subject: sub
        };
        console.log(data, sub);
        let res = await ApiCall('POST', '/sent_testmail', data, header);
        if (res?.data?.statusCode === 200) {
            setToastMsg(res.data.message);
            toggleActive();
        }
    }
    
    return (
        <>
            <Page fullWidth backAction={{ content: 'Products', onAction: () => editTemplate() }}
                primaryAction={<Button variant="primary" onClick={() => togglePreview()}>Preview</Button>} title={'Edit email content'}>
                <LegacyCard>
                    <LegacyCard.Section>
                        <EmailEditor
                            ref={emailEditorRef}
                            appearance={{
                                panels: {
                                    tools: {
                                        dock: 'left'
                                    }
                                }
                            }}
                            onLoad={onLoad}
                        />
                    </LegacyCard.Section>
                </LegacyCard>
                <Grid columns={{ sm: 3 }}>
                    <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                        <LegacyCard>
                            <div className='test-email'>
                                <LegacyCard.Section distribution="fillEvenly" spacing="loose">
                                    <LegacyStack>
                                        <LegacyStack.Item fill>
                                            <TextField
                                                label="Send test email"
                                                autoComplete="off"
                                                placeholder='abc@example.com'
                                                name="test_email"
                                                onChange={(value) => formik.setFieldValue('test_email', value)}
                                                value={formik.values.test_email}
                                                error={formik.errors.test_email ? formik.errors.test_email : null}
                                            />
                                        </LegacyStack.Item>
                                        <LegacyStack.Item>
                                            <div className='test-email-btn'>
                                                <Button onClick={() => formik.handleSubmit()}>Send test mail</Button>
                                            </div>
                                        </LegacyStack.Item>
                                    </LegacyStack>
                                </LegacyCard.Section>
                            </div>
                        </LegacyCard>
                    </Grid.Cell>
                    <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}></Grid.Cell>
                    <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                        <div className='save-btn'>
                            <Button primary onClick={() => exportHtml('save')} loading={loading}>Save & close</Button>
                        </div>
                    </Grid.Cell>
                </Grid>
                {toastMarkup}
            </Page>
        </>
    )
}
export default Edit_email;