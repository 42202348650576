import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { Grid, LegacyCard, Page, Button, Toast, TextField, Text, FormLayout } from '@shopify/polaris';
import { UploadIcon } from '../components/commonSvg';
// import { ApiCall } from '../helper/axios';
import { useSelector, useDispatch } from 'react-redux';
// import { storeData } from '../store/storeSlice';
import { NotificationFilledMajor } from '@shopify/polaris-icons';

const FeatureReqComment = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const clientStoreData = useSelector(state => state.clientStoreData);
    const storeInfo = clientStoreData.clientInfo?.storeInfo;
    const header = { authentication: storeInfo?.token };
    const shop = storeInfo?.shop_data?.store_name
    const app_status = storeInfo?.shop_data?.app_status;
    const [appStatus, setAppStatus] = useState(app_status);
    const [activeEnable, setActiveEnable] = useState(false);
    const [loading, setLoading] = useState(false);

    const toggleActive = useCallback(
        () => setActiveEnable((activeEnable) => !activeEnable),
        []
    );

    const toastMarkup = activeEnable ? (
        <Toast
            duration={5000}
            content={appStatus ? "Disable Successfully" : "Enable Successfully"}
            onDismiss={toggleActive}
        />
    ) : null;

    const options = [
        {
            label: 'Trending',
            value: 'trending',
            prefix: 'Order:',
        }
    ];

    return (
        <>
            <Page fullWidth backAction={{content: 'Back to all posts', onAction: () => navigate('/feature_req') }} title="Back to all posts">
                <Grid>
                    <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 3, xl: 3 }}>
                        <div className='feature_div'>
                            <LegacyCard title="Voters" sectioned>
                                <ul className="Polaris-ResourceList">
                                    <li className='voters-li'>
                                        <div>
                                            <div className="Polaris-LegacyStack--noWrap Polaris-LegacyStack">
                                                <div className="Polaris-LegacyStack__Item">
                                                    <span class="Polaris-Avatar Polaris-Avatar--sizeMedium Polaris-Avatar--shapeRound">
                                                        <span class="Polaris-Avatar__Initials">
                                                            <span><b>H</b></span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div class="Polaris-LegacyStack__Item Polaris-LegacyStack__Item--fill pt-2">
                                                    <h3 class="Polaris-Text--root Polaris-Text--bodyMd">hardik sorathiya</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className='voters-li'>
                                        <div>
                                            <div className="Polaris-LegacyStack--noWrap Polaris-LegacyStack">
                                                <div className="Polaris-LegacyStack__Item">
                                                    <span class="Polaris-Avatar Polaris-Avatar--sizeMedium Polaris-Avatar--shapeRound">
                                                        <span class="Polaris-Avatar__Initials">
                                                            <span><b>H</b></span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div class="Polaris-LegacyStack__Item Polaris-LegacyStack__Item--fill pt-2">
                                                    <h3 class="Polaris-Text--root Polaris-Text--bodyMd">hardik sorathiya</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </LegacyCard>
                        </div>
                    </Grid.Cell>
                    <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 9, xl: 9 }}>
                        <LegacyCard sectioned>
                            <div>
                                <ul className="Polaris-ResourceList">
                                    <li>
                                        <div>
                                            <div className="Polaris-LegacyStack--noWrap Polaris-LegacyStack">
                                                <div className="Polaris-LegacyStack__Item">
                                                    <div id="upvoteChange" className="vote-btn cursor-pointer">
                                                        <svg width="13" height="8" viewBox="0 0 13 8" fill="none">
                                                            <path d="M1.44332 8.00076H11.3584C12.5602 8.00076 13.2339 6.79418 12.4914 5.97166L7.53383 0.479635C6.95774 -0.158861 5.84558 -0.158861 5.26789 0.479635L0.310347 5.97326C-0.432165 6.79418 0.241537 8.00076 1.44332 8.00076Z" fill="#5C5F62" />
                                                        </svg>
                                                        <div className="vote-num">
                                                            <span data-upvote="20">9</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="Polaris-LegacyStack__Item Polaris-LegacyStack__Item--fill">
                                                    <div className='req-title'>
                                                        <Text variant="headingXl" as="h4">
                                                            Multi language feature
                                                        </Text>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <ul className="Polaris-ResourceList">
                                    <li className='voters-li'>
                                        <div>
                                            <div className="Polaris-LegacyStack--noWrap Polaris-LegacyStack">
                                                <div className="Polaris-LegacyStack__Item">
                                                    <span class="Polaris-Avatar Polaris-Avatar--sizeMedium Polaris-Avatar--shapeRound">
                                                        <span class="Polaris-Avatar__Initials">
                                                            <span><b>H</b></span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div class="Polaris-LegacyStack__Item Polaris-LegacyStack__Item--fill pt-2">
                                                    <h3 class="Polaris-Text--root Polaris-Text--bodyMd">hardik sorathiya</h3>
                                                    <div class="Polaris-Text--subdued pt-2">We need to send notifications based on the language the customers use to navigate the page.</div>
                                                    <div class="Polaris-Text--bodyMd">June 25, 2021</div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </LegacyCard>
                        <LegacyCard title="Leave a comment" sectioned>
                            <FormLayout>
                                <TextField
                                    multiline={4}
                                    placeholder="Type here..."
                                    autoComplete="off"
                                />
                                <div className='feature_btnGrp display-flex'>
                                    <div className='upload_icon_div'>
                                        <Button icon={UploadIcon}>Upload file</Button>
                                    </div>
                                    <div>
                                        <Button primary>Comment</Button>
                                    </div>
                                </div>
                            </FormLayout>
                        </LegacyCard>
                    </Grid.Cell>
                </Grid>
                {toastMarkup}
            </Page >
        </>
    );
}

export default FeatureReqComment;