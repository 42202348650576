import React, { useState, useCallback } from 'react';
import { Button, Layout, LegacyCard, Page, Text, List, Badge } from '@shopify/polaris';
import { useSelector } from 'react-redux';
import { ApiCall } from '../helper/axios';
import { host } from '../helper/commonApi';
import { useNavigate } from "react-router-dom";

const PricingPlan = () => {
    const navigate = useNavigate();
    const clientStoreData = useSelector(state => state.clientStoreData);
    const storeInfo = clientStoreData.clientInfo?.storeInfo;
    const header = { authentication: storeInfo?.token };
    const plan_type = storeInfo?.shop_data?.plan_type;
    const charge_approve_status = storeInfo?.shop_data?.charge_approve_status;

    const chnageApprove = async (type) => {
        const data = {
            host: host,
            plan_type: type
        }
        const res = await ApiCall('POST', '/upgrade_charges', data, header);
        if (res?.data?.statusCode === 200) {
            console.log(type !== 0, res.data.data?.confirm_url);
            if (parseInt(type) !== 0) {
                window.parent.location.href = res.data.data?.confirm_url;
            }else{
                navigate('/');
            }
        }
    }
    /* Do not remove
    const [rangeValue, setRangeValue] = useState(10000);
    const handleRangeSliderChange = useCallback(
        (value) => setRangeValue(value),
        [],
    ); */

    return (
        <>
            <Page fullWidth>
                <div className='pricing-plan-main'>
                    <Layout>
                        <Layout.Section variant="oneThird">
                            <LegacyCard>
                                <div className='price-free'>
                                    <LegacyCard.Section>
                                        <div className='pricing-text'>
                                            <Text variant="headingMd" as="h3">
                                                Free
                                            </Text>
                                            <Text variant="heading3xl" as="h3">
                                                Free
                                            </Text>
                                        </div>
                                    </LegacyCard.Section>
                                    <LegacyCard.Section>
                                        <div className='plan-dtl'>
                                            <List type="bullet">
                                                <List.Item>10 Email/month</List.Item>
                                                {/* <List.Item>*SMS charges extra</List.Item> */}
                                            </List>
                                        </div>
                                        <div className='price-upgrade'>
                                            <Button fullWidth primary onClick={() => chnageApprove('0')} disabled={charge_approve_status === '1' && plan_type === '0' ? 'disabled' : ''}>Upgrade plan</Button>
                                        </div>
                                    </LegacyCard.Section>
                                </div>
                            </LegacyCard>
                        </Layout.Section>
                        <Layout.Section variant="oneThird">
                            <LegacyCard>
                                <LegacyCard.Section>
                                    <div className='pricing-text'>
                                        <Text variant="headingMd" as="h3">
                                            starter
                                        </Text>
                                        <Text variant="heading3xl" as="h3">
                                            $19.99/month
                                        </Text>
                                        {/* <Text as="p" tone="success">or $192/year and save 20%</Text> */}
                                    </div>
                                </LegacyCard.Section>
                                <LegacyCard.Section>
                                    <List type="bullet">
                                        <List.Item>Everything in free plan plus: 300 Email/month</List.Item>
                                        <List.Item>Mailchimp/klaviyo integration</List.Item>
                                        <List.Item>Custom theme support</List.Item>
                                        <List.Item>Data export</List.Item>
                                    </List>
                                    <div className='price-upgrade'>
                                        <Button fullWidth primary onClick={() => chnageApprove('1')} disabled={charge_approve_status === '1' && plan_type === '1' ? 'disabled' : ''}>Upgrade plan</Button>
                                    </div>
                                </LegacyCard.Section>
                            </LegacyCard>
                        </Layout.Section>
                        <Layout.Section variant="oneThird">
                            <LegacyCard>
                                <LegacyCard.Section>
                                    <div className='pricing-text'>
                                        <Text variant="headingMd" as="h3">
                                            Pro
                                        </Text>
                                        <Text variant="heading3xl" as="h3">
                                            $29.99/month
                                        </Text>
                                        {/* <Text as="p" tone="success">or $287.90/year and save 20%</Text> */}
                                    </div>
                                </LegacyCard.Section>
                                <LegacyCard.Section>
                                    <div className='plan-dtl'>
                                        <List type="bullet">
                                            <List.Item>Everything in starter plan plus: 500 Email/month</List.Item>
                                            <List.Item>Collection page integration</List.Item>
                                        </List>
                                    </div>
                                    <div className='price-upgrade'>
                                        <Button fullWidth primary onClick={() => chnageApprove('2')} disabled={charge_approve_status === '1' && plan_type === '2' ? 'disabled' : ''}>Upgrade plan</Button>
                                    </div>
                                </LegacyCard.Section>
                            </LegacyCard>
                        </Layout.Section>
                        <Layout.Section variant="oneThird">
                            <LegacyCard>
                                <LegacyCard.Section>
                                    <div className='pricing-text'>
                                        <Text variant="headingMd" as="h3">
                                            Advance
                                        </Text>
                                        <Text variant="heading3xl" as="h3">
                                            $39.99/month
                                        </Text>
                                        {/* <Text as="p" tone="success">or $287.90/year and save 20%</Text> */}
                                    </div>
                                </LegacyCard.Section>
                                <LegacyCard.Section>
                                    <div className='plan-dtl'>
                                        <List type="bullet">
                                            <List.Item>Everything in starter plan plus: 1200 Email/month</List.Item>
                                            <List.Item>Collection page integration</List.Item>
                                        </List>
                                    </div>
                                    <div className='price-upgrade'>
                                        <Button fullWidth primary onClick={() => chnageApprove('3')} disabled={charge_approve_status === '1' && plan_type === '3' ? 'disabled' : ''}>Upgrade plan</Button>
                                    </div>
                                </LegacyCard.Section>
                            </LegacyCard>
                        </Layout.Section>
                    </Layout>
                </div>
                {/* Do not remove */}
                {/* <br></br> */}
                {/* <div className='pricing-month'>
                    <Layout>
                        <Layout.Section>
                            <div className="wrap">
                                <div className="left">
                                    <div className='plan-div'>
                                        <div>
                                            <Button>Monthly</Button>
                                            <Button primary>Yearly</Button>
                                        </div>
                                    </div>
                                </div>
                                <div className="right">
                                    <div className='badge-div'>
                                        <Badge progress="incomplete" tone="attention">Save up to 20%</Badge>
                                    </div>
                                </div>
                            </div>
                        </Layout.Section>
                    </Layout>
                </div> */}
                {/* <br></br>
                <div className='pricing-emails'>
                    <Layout>
                        <Layout.Section>
                            <LegacyCard sectioned title="Number of emails">
                                <RangeSlider
                                    output
                                    label="Email"
                                    min={0}
                                    max={10000}
                                    value={rangeValue}
                                    onChange={handleRangeSliderChange}
                                    prefix={<p>0</p>}
                                    suffix={
                                        <p
                                            style={{
                                                minWidth: '24px',
                                                textAlign: 'right',
                                            }}
                                        >
                                            {rangeValue}
                                        </p>
                                    }
                                />
                                <div className='num-email'>
                                    <div className='display-flex'>
                                        <Text variant="headingSm" as="h6">Amount</Text>
                                        <div className='amount-div'>
                                            <Text variant="headingSm" as="h6">$21.99</Text>
                                        </div>
                                    </div>
                                </div>
                                <div className='pb-2 pt-2'><Divider /></div>
                                <div className='display-flex'>
                                    <Text variant="headingMd" as="h6">Amount</Text>
                                    <div className='amount-div'>
                                        <Text variant="headingMd" as="h6">$21.99</Text>
                                    </div>
                                </div>
                                <div className='pt-2'>
                                    <Button fullWidth primary>Get started</Button>
                                </div>
                            </LegacyCard>
                        </Layout.Section>
                    </Layout>
                </div> */}
            </Page>
        </>
    );
}
export default PricingPlan;