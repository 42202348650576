import React, { useCallback, useState } from 'react';
import { IndexFiltersMode, ChoiceList, DataTable, IndexFilters, LegacyCard, Page, Text, useSetIndexFiltersMode } from '@shopify/polaris';

const ProductFilter = () => {
  const sleep = (ms) =>
    new Promise((resolve) => setTimeout(resolve, ms));
  const [itemStrings, setItemStrings] = useState([]);
  const deleteView = (index) => {
    const newItemStrings = [...itemStrings];
    newItemStrings.splice(index, 1);
    setItemStrings(newItemStrings);
    Product_selected(0);
  };

  const duplicateView = async (name) => {
    setItemStrings([...itemStrings, name]);
    Product_selected(itemStrings.length);
    await sleep(1);
    return true;
  };

  const Product_tabs = itemStrings.map((item, index) => ({
    content: item,
    index,
    onAction: () => { },
    id: `${item}-${index}`,
    isLocked: index === 0,
    actions:
      index === 0
        ? []
        : [
          {
            type: 'rename',
            onAction: () => { },
            onPrimaryAction: async (value) => {
              const newItemsStrings = Product_tabs.map((item, idx) => {
                if (idx === index) {
                  return value;
                }
                return item.content;
              });
              await sleep(1);
              setItemStrings(newItemsStrings);
              return true;
            },
          },
          {
            type: 'duplicate',
            onPrimaryAction: async (value) => {
              await sleep(1);
              duplicateView(value);
              return true;
            },
          },
          {
            type: 'edit',
          },
          {
            type: 'delete',
            onPrimaryAction: async () => {
              await sleep(1);
              deleteView(index);
              return true;
            },
          },
        ],
  }));
  const [Product_selected, Product_setSelected] = useState(0);
  const Product_onCreateNewView = async (value) => {
    await sleep(500);
    setItemStrings([...itemStrings, value]);
    Product_selected(itemStrings.length);
    return true;
  };
  const Product_sortOptions = [
    { label: 'Order', value: 'order asc', directionLabel: 'Ascending' },
    { label: 'Order', value: 'order desc', directionLabel: 'Descending' },
    { label: 'Customer', value: 'customer asc', directionLabel: 'A-Z' },
    { label: 'Customer', value: 'customer desc', directionLabel: 'Z-A' },
    { label: 'Date', value: 'date asc', directionLabel: 'A-Z' },
    { label: 'Date', value: 'date desc', directionLabel: 'Z-A' },
    { label: 'Total', value: 'total asc', directionLabel: 'Ascending' },
    { label: 'Total', value: 'total desc', directionLabel: 'Descending' },
  ];
  const [Product_sortSelected, Product_setSortSelected] = useState(['order asc']);
  const { mode, setMode } = useSetIndexFiltersMode(IndexFiltersMode.Filtering);
  const Product_onHandleCancel = () => { };

  const [accountStatus, setAccountStatus] = useState(undefined,);
  const [moneySpent, setMoneySpent] = useState(undefined,);
  const [taggedWith, setTaggedWith] = useState('');
  const [Product_queryValue, Product_setQueryValue] = useState('');

  const handleAccountStatusChange = useCallback(
    (value) => setAccountStatus(value),
    [],
  );
  const Product_handleFiltersQueryChange = useCallback(
    (value) => Product_setQueryValue(value),
    [],
  );
  const handleAccountStatusRemove = useCallback(
    () => setAccountStatus(undefined),
    [],
  );
  const handleMoneySpentRemove = useCallback(
    () => setMoneySpent(undefined),
    [],
  );
  const handleTaggedWithRemove = useCallback(() => setTaggedWith(''), []);
  const handleQueryValueRemove = useCallback(() => Product_setQueryValue(''), []);
  const Product_handleFiltersClearAll = useCallback(() => {
    handleAccountStatusRemove();
    handleMoneySpentRemove();
    handleTaggedWithRemove();
    handleQueryValueRemove();
  }, [
    handleAccountStatusRemove,
    handleMoneySpentRemove,
    handleQueryValueRemove,
    handleTaggedWithRemove,
  ]);

  const Product_filters = [
    {
      key: 'accountStatus',
      label: 'Account status',
      filter: (
        <ChoiceList
          title="Account status"
          titleHidden
          choices={[
            { label: 'Enabled', value: 'enabled' },
            { label: 'Not invited', value: 'not invited' },
            { label: 'Invited', value: 'invited' },
            { label: 'Declined', value: 'declined' },
          ]}
          selected={accountStatus || []}
          onChange={handleAccountStatusChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: 'taggedWith',
      label: 'Tagged with',
      shortcut: true,
    },
    {
      key: 'moneySpent',
      label: 'Money spent',
      shortcut: true,
    },
  ];

  const Product_appliedFilters = [];
  if (accountStatus && !isEmpty(accountStatus)) {
    const key = 'accountStatus';
    Product_appliedFilters.push({
      key,
      label: disambiguateLabel(key, accountStatus),
      onRemove: handleAccountStatusRemove,
    });
  }
  if (moneySpent) {
    const key = 'moneySpent';
    Product_appliedFilters.push({
      key,
      label: disambiguateLabel(key, moneySpent),
      onRemove: handleMoneySpentRemove,
    });
  }
  if (!isEmpty(taggedWith)) {
    const key = 'taggedWith';
    Product_appliedFilters.push({
      key,
      label: disambiguateLabel(key, taggedWith),
      onRemove: handleTaggedWithRemove,
    });
  }

  function disambiguateLabel(key, value) {
    switch (key) {
      case 'moneySpent':
        return `Money spent is between $${value[0]} and $${value[1]}`;
      case 'taggedWith':
        return `Tagged with ${value} `;
      case 'accountStatus':
        return (value).map((val) => `Customer ${val}`).join(', ');
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === '' || value == null;
    }
  }
  const handalSort = () => { };
  const abc = [
    {
      product_title: 'Mindfulness',
      variant_id: '37876667482289',
      variant: 'Brillare',
      unsent: '0',
      sent: '5',
      total: '5',
      last_added: 'Aug 22, 2021',
      last_sent: 'Aug 24, 2021'
    },
    {
      product_title: 'Mindfulness',
      variant_id: '37876667482289',
      variant: 'Brillare',
      unsent: '0',
      sent: '5',
      total: '5',
      last_added: 'Aug 22, 2021',
      last_sent: 'Aug 24, 2021'
    }, {
      product_title: 'Mindfulness',
      variant_id: '37876667482289',
      variant: 'Brillare',
      unsent: '0',
      sent: '5',
      total: '5',
      last_added: 'Aug 22, 2021',
      last_sent: 'Aug 24, 2021'
    }
  ]
  const rows = abc?.map((item) => [
    <Text>{item.product_title}</Text>,
    <Text>{item.variant_id}</Text>,
    <Text>{item.variant}</Text>,
    <Text>{item.unsent}</Text>,
    <Text>{item.sent}</Text>,
    <Text>{item.total}</Text>,
    <Text>{item.last_added}</Text>,
    <Text>{item.last_sent}</Text>,
  ])
  return (
    <>
      <Page>
        <div className='product'>
          <LegacyCard>
            <IndexFilters
              sortOptions={Product_sortOptions}
              sortSelected={Product_sortSelected}
              queryValue={Product_queryValue}
              queryPlaceholder="Search"
              onQueryChange={Product_handleFiltersQueryChange}
              onQueryClear={() => { }}
              onSort={Product_setSortSelected}
              cancelAction={{
                onAction: Product_onHandleCancel,
                disabled: true,
                loading: false,
              }}
              tabs={Product_tabs}
              selected={Product_selected}
              onSelect={Product_selected}
              canCreateNewView
              onCreateNewView={Product_onCreateNewView}
              filters={Product_filters}
              appliedFilters={Product_appliedFilters}
              onClearAll={Product_handleFiltersClearAll}
              mode={mode}
              setMode={setMode}
            />
            <DataTable
              columnContentTypes={[
                'text',
                'number',
                'text',
                'text',
                'text',
                'text',
                'text',
                'text'
              ]}
              headings={[
                'Product title',
                'Variant id',
                'Variant',
                'Unsent',
                'Sent',
                'Total',
                'Last added',
                'Last Sent',
              ]}
              rows={rows}
              sortable={[false, false, false, false, false, false, true, true]}
              onSort={handalSort}
            />
          </LegacyCard>
        </div>
      </Page>
    </>
  )
}

export default ProductFilter;