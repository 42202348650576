import { TextField } from '@shopify/polaris'
import React from 'react'

const PolarisColorInput = ({ value, handlechange, placeholder, label, error, onBlur, name }) => {
    
    return (
        <>
            <div className='custom-input-style1'>
                <TextField
                    label={label}
                    value={value}
                    onBlur={onBlur}
                    name={name}
                    placeholder={placeholder}
                    prefix={<div>
                        <TextField
                            value={value}
                            type='color'
                            onChange={handlechange}
                        />
                    </div>}
                    onChange={handlechange}
                    autoComplete="off"
                    error={error}
                />
            </div>
        </>
    )
}

export default PolarisColorInput