import { Badge, ChoiceList, DataTable, Divider, Icon, IndexFilters, IndexTable, LegacyCard, Page, RangeSlider, Text, TextField, useIndexResourceState, useSetIndexFiltersMode } from '@shopify/polaris'
import { SearchMinor, SortMinor } from '@shopify/polaris-icons'
import React, { useCallback, useState } from 'react'

function Customer() {
    const sleep = (ms) =>
        new Promise((resolve) => setTimeout(resolve, ms));
    const [itemStrings, setItemStrings] = useState([
        'All',
        'Unsent',
        'Sent',
    ]);
    const deleteView = (index) => {
        const newItemStrings = [...itemStrings];
        newItemStrings.splice(index, 1);
        setItemStrings(newItemStrings);
        setSelected(0);
    };

    const duplicateView = async (name) => {
        setItemStrings([...itemStrings, name]);
        setSelected(itemStrings.length);
        await sleep(1);
        return true;
    };

    const tabs = itemStrings.map((item, index) => ({
        content: item,
        index,
        onAction: () => { },
        id: `${item}-${index}`,
        isLocked: index === 0,
        actions:
            index === 0
                ? []
                : [
                    {
                        type: 'rename',
                        onAction: () => { },
                        onPrimaryAction: async (value) => {
                            const newItemsStrings = tabs.map((item, idx) => {
                                if (idx === index) {
                                    return value;
                                }
                                return item.content;
                            });
                            await sleep(1);
                            setItemStrings(newItemsStrings);
                            return true;
                        },
                    },
                    {
                        type: 'duplicate',
                        onPrimaryAction: async (value) => {
                            await sleep(1);
                            duplicateView(value);
                            return true;
                        },
                    },
                    {
                        type: 'edit',
                    },
                    {
                        type: 'delete',
                        onPrimaryAction: async () => {
                            await sleep(1);
                            deleteView(index);
                            return true;
                        },
                    },
                ],
    }));
    const [selected, setSelected] = useState(0);
    const onCreateNewView = async (value) => {
        await sleep(500);
        setItemStrings([...itemStrings, value]);
        setSelected(itemStrings.length);
        return true;
    };
    const sortOptions = [
        { label: 'Order', value: 'order asc', directionLabel: 'Ascending' },
        { label: 'Order', value: 'order desc', directionLabel: 'Descending' },
        { label: 'Customer', value: 'customer asc', directionLabel: 'A-Z' },
        { label: 'Customer', value: 'customer desc', directionLabel: 'Z-A' },
        { label: 'Date', value: 'date asc', directionLabel: 'A-Z' },
        { label: 'Date', value: 'date desc', directionLabel: 'Z-A' },
        { label: 'Total', value: 'total asc', directionLabel: 'Ascending' },
        { label: 'Total', value: 'total desc', directionLabel: 'Descending' },
    ];
    const [sortSelected, setSortSelected] = useState(['order asc']);
    const { mode, setMode } = useSetIndexFiltersMode();
    const onHandleCancel = () => { };

    const onHandleSave = async () => {
        await sleep(1);
        return true;
    };

    const primaryAction =
        selected === 0
            ? {
                type: 'save-as',
                onAction: onCreateNewView,
                disabled: false,
                loading: false,
            }
            : {
                type: 'save',
                onAction: onHandleSave,
                disabled: false,
                loading: false,
            };
    const [accountStatus, setAccountStatus] = useState(undefined,);
    const [moneySpent, setMoneySpent] = useState(undefined,);
    const [taggedWith, setTaggedWith] = useState('');
    const [queryValue, setQueryValue] = useState('');

    const handleAccountStatusChange = useCallback(
        (value) => setAccountStatus(value),
        [],
    );
    const handleMoneySpentChange = useCallback(
        (value) => setMoneySpent(value),
        [],
    );
    const handleTaggedWithChange = useCallback(
        (value) => setTaggedWith(value),
        [],
    );
    const handleFiltersQueryChange = useCallback(
        (value) => setQueryValue(value),
        [],
    );
    const handleAccountStatusRemove = useCallback(
        () => setAccountStatus(undefined),
        [],
    );
    const handleMoneySpentRemove = useCallback(
        () => setMoneySpent(undefined),
        [],
    );
    const handleTaggedWithRemove = useCallback(() => setTaggedWith(''), []);
    const handleQueryValueRemove = useCallback(() => setQueryValue(''), []);
    const handleFiltersClearAll = useCallback(() => {
        handleAccountStatusRemove();
        handleMoneySpentRemove();
        handleTaggedWithRemove();
        handleQueryValueRemove();
    }, [
        handleAccountStatusRemove,
        handleMoneySpentRemove,
        handleQueryValueRemove,
        handleTaggedWithRemove,
    ]);

    const filters = [
        {
            key: 'accountStatus',
            label: 'Email',
            filter: (
                <TextField
                    label="Email"
                    //   value={value}
                    //   onChange={handleChange}
                    autoComplete="off"
                />
            ),
            shortcut: true,
        },
        {
            key: 'taggedWith',
            label: 'Product Name',
            filter: (
              <TextField
                label="Product Name"
                value={taggedWith}
                // onChange={handleTaggedWithChange}
                autoComplete="off"
                labelHidden
              />
            ),
            shortcut: true,
        },
        {
            key: 'moneySpent',
            label: 'Date',
            filter: (
                <TextField
                  label="Product Name"
                  value={taggedWith}
                  // onChange={handleTaggedWithChange}
                  autoComplete="off"
                  labelHidden
                />
              ),
              shortcut: true,
        },
    ];

    const appliedFilters = [];
    if (accountStatus && !isEmpty(accountStatus)) {
        const key = 'accountStatus';
        appliedFilters.push({
            key,
            label: disambiguateLabel(key, accountStatus),
            onRemove: handleAccountStatusRemove,
        });
    }
    if (moneySpent) {
        const key = 'moneySpent';
        appliedFilters.push({
            key,
            label: disambiguateLabel(key, moneySpent),
            onRemove: handleMoneySpentRemove,
        });
    }
    if (!isEmpty(taggedWith)) {
        const key = 'taggedWith';
        appliedFilters.push({
            key,
            label: disambiguateLabel(key, taggedWith),
            onRemove: handleTaggedWithRemove,
        });
    }

    const [abc, setabc] = useState([
        {
            Email: <div className='display-email'><svg width="16" height="12" viewBox="0 0 16 12" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M1.5 0C1.10218 0 0.720644 0.158035 0.43934 0.43934C0.158035 0.720644 0 1.10218 0 1.5V10.5C0 10.8978 0.158035 11.2794 0.43934 11.5607C0.720644 11.842 1.10218 12 1.5 12H14.5C14.8978 12 15.2794 11.842 15.5607 11.5607C15.842 11.2794 16 10.8978 16 10.5V1.5C16 1.10218 15.842 0.720644 15.5607 0.43934C15.2794 0.158035 14.8978 0 14.5 0H1.5ZM3.515 2.143C3.40239 2.07253 3.27687 2.0252 3.14577 2.00379C3.01466 1.98237 2.88061 1.98728 2.75142 2.01825C2.62224 2.04921 2.50052 2.10561 2.39338 2.18413C2.28623 2.26266 2.19581 2.36175 2.12738 2.47562C2.05896 2.58948 2.01391 2.71583 1.99486 2.8473C1.97581 2.97877 1.98315 3.11272 2.01644 3.24132C2.04973 3.36992 2.10831 3.4906 2.18876 3.59631C2.26921 3.70202 2.36992 3.79064 2.485 3.857L7.485 6.857C7.64053 6.95044 7.81856 6.99981 8 6.99981C8.18144 6.99981 8.35947 6.95044 8.515 6.857L13.515 3.857C13.6301 3.79064 13.7308 3.70202 13.8112 3.59631C13.8917 3.4906 13.9503 3.36992 13.9836 3.24132C14.0169 3.11272 14.0242 2.97877 14.0051 2.8473C13.9861 2.71583 13.941 2.58948 13.8726 2.47562C13.8042 2.36175 13.7138 2.26266 13.6066 2.18413C13.4995 2.10561 13.3778 2.04921 13.2486 2.01825C13.1194 1.98728 12.9853 1.98237 12.8542 2.00379C12.7231 2.0252 12.5976 2.07253 12.485 2.143L8 4.833L3.515 2.143Z" fill="#5C5F62" /></svg><div>tempemail@gmail.com</div></div>,
            Marketing_Accepted: <svg width="20" height="20" viewBox="0 0 20 20" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2ZM7.707 6.293C7.5184 6.11084 7.2658 6.01005 7.0036 6.01233C6.7414 6.0146 6.49059 6.11977 6.30518 6.30518C6.11977 6.49059 6.0146 6.7414 6.01233 7.0036C6.01005 7.2658 6.11084 7.5184 6.293 7.707L8.586 10L6.293 12.293C6.19749 12.3852 6.12131 12.4956 6.0689 12.6176C6.01649 12.7396 5.9889 12.8708 5.98775 13.0036C5.9866 13.1364 6.0119 13.2681 6.06218 13.391C6.11246 13.5139 6.18671 13.6255 6.2806 13.7194C6.3745 13.8133 6.48615 13.8875 6.60905 13.9378C6.73194 13.9881 6.86362 14.0134 6.9964 14.0123C7.12918 14.0111 7.2604 13.9835 7.3824 13.9311C7.50441 13.8787 7.61475 13.8025 7.707 13.707L10 11.414L12.293 13.707C12.3852 13.8025 12.4956 13.8787 12.6176 13.9311C12.7396 13.9835 12.8708 14.0111 13.0036 14.0123C13.1364 14.0134 13.2681 13.9881 13.391 13.9378C13.5139 13.8875 13.6255 13.8133 13.7194 13.7194C13.8133 13.6255 13.8875 13.5139 13.9378 13.391C13.9881 13.2681 14.0134 13.1364 14.0123 13.0036C14.0111 12.8708 13.9835 12.7396 13.9311 12.6176C13.8787 12.4956 13.8025 12.3852 13.707 12.293L11.414 10L13.707 7.707C13.8892 7.5184 13.99 7.2658 13.9877 7.0036C13.9854 6.7414 13.8802 6.49059 13.6948 6.30518C13.5094 6.11977 13.2586 6.0146 12.9964 6.01233C12.7342 6.01005 12.4816 6.11084 12.293 6.293L10 8.586L7.707 6.293Z" fill="#D82C0D" /></svg>,
            Product_title: '12 Ti Xelium skis',
            Variant: '163cm /black',
            Added: 'May 13, 2023',
            Sent: '',
            Purchase: '',
        },
        {
            Email: <div className='display-email'><svg width="16" height="12" viewBox="0 0 16 12" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M1.5 0C1.10218 0 0.720644 0.158035 0.43934 0.43934C0.158035 0.720644 0 1.10218 0 1.5V10.5C0 10.8978 0.158035 11.2794 0.43934 11.5607C0.720644 11.842 1.10218 12 1.5 12H14.5C14.8978 12 15.2794 11.842 15.5607 11.5607C15.842 11.2794 16 10.8978 16 10.5V1.5C16 1.10218 15.842 0.720644 15.5607 0.43934C15.2794 0.158035 14.8978 0 14.5 0H1.5ZM3.515 2.143C3.40239 2.07253 3.27687 2.0252 3.14577 2.00379C3.01466 1.98237 2.88061 1.98728 2.75142 2.01825C2.62224 2.04921 2.50052 2.10561 2.39338 2.18413C2.28623 2.26266 2.19581 2.36175 2.12738 2.47562C2.05896 2.58948 2.01391 2.71583 1.99486 2.8473C1.97581 2.97877 1.98315 3.11272 2.01644 3.24132C2.04973 3.36992 2.10831 3.4906 2.18876 3.59631C2.26921 3.70202 2.36992 3.79064 2.485 3.857L7.485 6.857C7.64053 6.95044 7.81856 6.99981 8 6.99981C8.18144 6.99981 8.35947 6.95044 8.515 6.857L13.515 3.857C13.6301 3.79064 13.7308 3.70202 13.8112 3.59631C13.8917 3.4906 13.9503 3.36992 13.9836 3.24132C14.0169 3.11272 14.0242 2.97877 14.0051 2.8473C13.9861 2.71583 13.941 2.58948 13.8726 2.47562C13.8042 2.36175 13.7138 2.26266 13.6066 2.18413C13.4995 2.10561 13.3778 2.04921 13.2486 2.01825C13.1194 1.98728 12.9853 1.98237 12.8542 2.00379C12.7231 2.0252 12.5976 2.07253 12.485 2.143L8 4.833L3.515 2.143Z" fill="#5C5F62" /></svg><div>tempemail@gmail.com</div></div>,
            Marketing_Accepted: <svg width="20" height="20" viewBox="0 0 20 20" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2ZM7.707 6.293C7.5184 6.11084 7.2658 6.01005 7.0036 6.01233C6.7414 6.0146 6.49059 6.11977 6.30518 6.30518C6.11977 6.49059 6.0146 6.7414 6.01233 7.0036C6.01005 7.2658 6.11084 7.5184 6.293 7.707L8.586 10L6.293 12.293C6.19749 12.3852 6.12131 12.4956 6.0689 12.6176C6.01649 12.7396 5.9889 12.8708 5.98775 13.0036C5.9866 13.1364 6.0119 13.2681 6.06218 13.391C6.11246 13.5139 6.18671 13.6255 6.2806 13.7194C6.3745 13.8133 6.48615 13.8875 6.60905 13.9378C6.73194 13.9881 6.86362 14.0134 6.9964 14.0123C7.12918 14.0111 7.2604 13.9835 7.3824 13.9311C7.50441 13.8787 7.61475 13.8025 7.707 13.707L10 11.414L12.293 13.707C12.3852 13.8025 12.4956 13.8787 12.6176 13.9311C12.7396 13.9835 12.8708 14.0111 13.0036 14.0123C13.1364 14.0134 13.2681 13.9881 13.391 13.9378C13.5139 13.8875 13.6255 13.8133 13.7194 13.7194C13.8133 13.6255 13.8875 13.5139 13.9378 13.391C13.9881 13.2681 14.0134 13.1364 14.0123 13.0036C14.0111 12.8708 13.9835 12.7396 13.9311 12.6176C13.8787 12.4956 13.8025 12.3852 13.707 12.293L11.414 10L13.707 7.707C13.8892 7.5184 13.99 7.2658 13.9877 7.0036C13.9854 6.7414 13.8802 6.49059 13.6948 6.30518C13.5094 6.11977 13.2586 6.0146 12.9964 6.01233C12.7342 6.01005 12.4816 6.11084 12.293 6.293L10 8.586L7.707 6.293Z" fill="#D82C0D" /></svg>,
            Product_title: '12 Ti Xelium skis',
            Variant: '163cm /black',
            Added: 'May 11, 2023',
            Sent: '',
            Purchase: '',
        }, {
            Email: <div className='display-email'><svg width="16" height="12" viewBox="0 0 16 12" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M1.5 0C1.10218 0 0.720644 0.158035 0.43934 0.43934C0.158035 0.720644 0 1.10218 0 1.5V10.5C0 10.8978 0.158035 11.2794 0.43934 11.5607C0.720644 11.842 1.10218 12 1.5 12H14.5C14.8978 12 15.2794 11.842 15.5607 11.5607C15.842 11.2794 16 10.8978 16 10.5V1.5C16 1.10218 15.842 0.720644 15.5607 0.43934C15.2794 0.158035 14.8978 0 14.5 0H1.5ZM3.515 2.143C3.40239 2.07253 3.27687 2.0252 3.14577 2.00379C3.01466 1.98237 2.88061 1.98728 2.75142 2.01825C2.62224 2.04921 2.50052 2.10561 2.39338 2.18413C2.28623 2.26266 2.19581 2.36175 2.12738 2.47562C2.05896 2.58948 2.01391 2.71583 1.99486 2.8473C1.97581 2.97877 1.98315 3.11272 2.01644 3.24132C2.04973 3.36992 2.10831 3.4906 2.18876 3.59631C2.26921 3.70202 2.36992 3.79064 2.485 3.857L7.485 6.857C7.64053 6.95044 7.81856 6.99981 8 6.99981C8.18144 6.99981 8.35947 6.95044 8.515 6.857L13.515 3.857C13.6301 3.79064 13.7308 3.70202 13.8112 3.59631C13.8917 3.4906 13.9503 3.36992 13.9836 3.24132C14.0169 3.11272 14.0242 2.97877 14.0051 2.8473C13.9861 2.71583 13.941 2.58948 13.8726 2.47562C13.8042 2.36175 13.7138 2.26266 13.6066 2.18413C13.4995 2.10561 13.3778 2.04921 13.2486 2.01825C13.1194 1.98728 12.9853 1.98237 12.8542 2.00379C12.7231 2.0252 12.5976 2.07253 12.485 2.143L8 4.833L3.515 2.143Z" fill="#5C5F62" /></svg><div>tempemail@gmail.com</div></div>,
            Marketing_Accepted: <svg width="20" height="20" viewBox="0 0 20 20" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2ZM7.707 6.293C7.5184 6.11084 7.2658 6.01005 7.0036 6.01233C6.7414 6.0146 6.49059 6.11977 6.30518 6.30518C6.11977 6.49059 6.0146 6.7414 6.01233 7.0036C6.01005 7.2658 6.11084 7.5184 6.293 7.707L8.586 10L6.293 12.293C6.19749 12.3852 6.12131 12.4956 6.0689 12.6176C6.01649 12.7396 5.9889 12.8708 5.98775 13.0036C5.9866 13.1364 6.0119 13.2681 6.06218 13.391C6.11246 13.5139 6.18671 13.6255 6.2806 13.7194C6.3745 13.8133 6.48615 13.8875 6.60905 13.9378C6.73194 13.9881 6.86362 14.0134 6.9964 14.0123C7.12918 14.0111 7.2604 13.9835 7.3824 13.9311C7.50441 13.8787 7.61475 13.8025 7.707 13.707L10 11.414L12.293 13.707C12.3852 13.8025 12.4956 13.8787 12.6176 13.9311C12.7396 13.9835 12.8708 14.0111 13.0036 14.0123C13.1364 14.0134 13.2681 13.9881 13.391 13.9378C13.5139 13.8875 13.6255 13.8133 13.7194 13.7194C13.8133 13.6255 13.8875 13.5139 13.9378 13.391C13.9881 13.2681 14.0134 13.1364 14.0123 13.0036C14.0111 12.8708 13.9835 12.7396 13.9311 12.6176C13.8787 12.4956 13.8025 12.3852 13.707 12.293L11.414 10L13.707 7.707C13.8892 7.5184 13.99 7.2658 13.9877 7.0036C13.9854 6.7414 13.8802 6.49059 13.6948 6.30518C13.5094 6.11977 13.2586 6.0146 12.9964 6.01233C12.7342 6.01005 12.4816 6.11084 12.293 6.293L10 8.586L7.707 6.293Z" fill="#D82C0D" /></svg>,
            Product_title: '12 Ti Xelium skis',
            Variant: '163cm /black',
            Added: 'May 12, 2023',
            Sent: '',
            Purchase: '',
        }
    ])
    const resourceName = {
        singular: 'order',
        plural: 'orders',
    };

    const { selectedResources, allResourcesSelected, handleSelectionChange } =
        useIndexResourceState(abc);

    const rowMarkup = abc.map(
        (
            item,
            index,
        ) => (
            <IndexTable.Row
                id={index}
                key={index}
                selected={selectedResources.includes(index)}
                position={index}
            >
                <IndexTable.Cell>
                    <Text>
                        {item.Email}
                    </Text>
                </IndexTable.Cell>
                <IndexTable.Cell>{item.Marketing_Accepted}</IndexTable.Cell>
                <IndexTable.Cell>{item.Product_title}</IndexTable.Cell>
                <IndexTable.Cell>{item.Variant}</IndexTable.Cell>
                <IndexTable.Cell>{item.Added}</IndexTable.Cell>
                <IndexTable.Cell>{item.Sent}</IndexTable.Cell>
                <IndexTable.Cell>{item.Purchase}</IndexTable.Cell>
            </IndexTable.Row>
        ),
    );
    function disambiguateLabel(key, value) {
        switch (key) {
            case 'moneySpent':
                return `Money spent is between $${value[0]} and $${value[1]}`;
            case 'taggedWith':
                return `Tagged with `;
            case 'accountStatus':
                return (value).map((val) => `Customer ${val}`).join(', ');
            default:
                return value;
        }
    }

    function isEmpty(value) {
        if (Array.isArray(value)) {
            return value.length === 0;
        } else {
            return value === '' || value == null;
        }
    }
    const handalSort = () => {
        // const abc = [5,3,0,1,4]
        const sort = abc.sort((item, index) => item.Added - index)
        console.log(sort, 'sort')
    };
    const bulkActions = [
        {
            content: 'Add tags',
            onAction: () => console.log('Todo: implement bulk add tags'),
        },
        {
            content: 'Remove tags',
            onAction: () => console.log('Todo: implement bulk remove tags'),
        },
        {
            content: 'Delete orders',
            onAction: () => console.log('Todo: implement bulk delete'),
        },
    ];
    const promotedBulkActions = [
        {
            content: 'Delete notification',
            onAction: () => console.log('Todo: implement bulk edit'),
        },
    ];
    return (
        <>
            <Page>
                <div className='customer'>
                    <LegacyCard>
                        <IndexFilters
                            sortOptions={sortOptions}
                            sortSelected={sortSelected}
                            queryValue={queryValue}
                            queryPlaceholder="Search"
                            onQueryChange={handleFiltersQueryChange}
                            onQueryClear={() => { }}
                            onSort={setSortSelected}
                            cancelAction={{
                                onAction: onHandleCancel,
                                disabled: false,
                                loading: false,
                            }}
                            tabs={tabs}
                            selected={selected}
                            onSelect={setSelected}
                            canCreateNewView
                            onCreateNewView={onCreateNewView}
                            filters={filters}
                            appliedFilters={appliedFilters}
                            onClearAll={handleFiltersClearAll}
                            mode={mode}
                            setMode={setMode}
                        />
                        <IndexTable
                            resourceName={resourceName}
                            itemCount={abc.length}
                            selectedItemsCount={
                                allResourcesSelected ? 'All' : selectedResources.length
                            }
                            onSelectionChange={handleSelectionChange}
                            headings={[
                                { title: 'Email/Phone' },
                                { title: 'Marketing Accepted?' },
                                { title: 'Product title' },
                                { title: 'Variant' },
                                { title: 'Added' },
                                { title: 'Sent' },
                                { title: 'Purchase' },
                            ]}
                            sortable={[false, false, false, false, true, true, false]}
                            onSort={handalSort}
                            bulkActions={bulkActions}
                            promotedBulkActions={promotedBulkActions}
                        >
                            {rowMarkup}
                        </IndexTable>
                    </LegacyCard>
                </div>
            </Page>
        </>
    )
}

export default Customer