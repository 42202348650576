import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiEndpoint, host, shop, config } from '../helper/commonApi';
import axios from 'axios';
import { ApiCall } from '../helper/axios';

export const GenerateToken = createAsyncThunk('fetch/generateToken',
  async () => {
    const res = await axios.post(`${apiEndpoint}/generate-token`, {
        shop: shop,
    });
    return res?.data?.data;
  });

const storeSlice = createSlice({
  name: 'clinetStore',
  initialState: {},
  reducers: {
    storeData: (state, action) => {
      state.storeInfo = (action.payload);
    },
  },
  extraReducers: builder => {
    builder.addCase(GenerateToken.pending, state => {
      state.status = false;
    }).addCase(GenerateToken.fulfilled, (state, action) => {
      let { payload } = action;
      state.clientInfo = {
        storeInfo: payload,
      };
      state.status = true;
    }).addCase(GenerateToken.rejected, () => {
      window.parent.location.href = `${apiEndpoint}/?shop=${shop}&host=${host}`;
    });
  }
})

export const { storeData } = storeSlice.actions;
export default storeSlice.reducer;