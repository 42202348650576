import { configureStore } from "@reduxjs/toolkit";
import storeReducer from './storeSlice';
import emailReducer from './emailSlice';

const clientStoreData = configureStore({
    reducer: {
        clientStoreData: storeReducer,
        emailData: emailReducer
    },
    middleware: getDefaultMiddleware => {
      return getDefaultMiddleware({
        serializableCheck: false
      })
    }
  })

export default clientStoreData;